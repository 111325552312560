import React, {useEffect, useRef, useState} from "react";
import {useCookies} from "react-cookie";
import {useTranslation} from "react-i18next";
import './style.css'
import CloseIcon from "@mui/icons-material/Close";
import {Modal} from "@mui/material";
import Axios from "../../helpers/Axios";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DateTimePicker from 'react-datetime-picker';
import imgCash from '../../media/cash.png'
import imgIdram from '../../media/idram.png'
import imgVisaMasterArca from '../../media/visa-master-arca.png'
import noImage from '../../media/no-image.png'
import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'

export default function CheckoutModal(props){
    const [cookies, setCookie, removeCookie] = useCookies()

    const [availableCities, setAvailableCities] = useState()
    const [asap, setAsap] = useState(true)
    const [deliveryTime, setDeliveryTime] = useState(new Date())
    const [city, setCity] = useState()
    const [address, setAddress] = useState(cookies.user ? cookies.user.address : '')
    const [phoneNumber, setPhoneNumber] = useState(cookies.user ? cookies.user.phone_number : '')
    const [additional, setAdditional] = useState()
    const [totalWithDelivery, setTotalWithDelivery] = useState()
    const [payment, setPayment] = useState('cash')
    const [errorMsg, setErrorMsg] = useState({city: false, address: false, phoneNumber: false, cart: false, msg: ''})


    const { i18n, t } = useTranslation()

    useEffect(() => {
        if(!cookies.user){
            Axios.get('get-available-cities/', {params : cookies.auth}).then(res => {
                if(res.data){
                    setAvailableCities(JSON.parse(res.data.available_cities))
                }
            })
        }
    }, [])

    useEffect(() => {
        if(availableCities && city){
            let selectedCity = availableCities.find(element => element.pk === +city)
            setTotalWithDelivery(props.totalPrice + +selectedCity.fields.delivery_price)
        } else if(cookies.user) {
            setTotalWithDelivery((Math.round(props.totalPrice) - (Math.round(props.totalPrice) / 100 * +cookies.user.discount_in_percent)) + +cookies.user.delivery_price)
        }
    }, [city, props.totalPrice])

    useEffect(() => {
        setTimeout(() => {
            errorMsg.msg = ''
            setErrorMsg({...errorMsg})
        }, 20000)
    },  [errorMsg])

    const validateOrderForm = () => {
        let errorProps = {city: false, address: false, phoneNumber: false, cart: false, msg: ''}
        let isValid = true
        if(!cookies.user && !city) {
            errorProps.msg += t`error_choose_city` + '  '
            errorProps.city = true
            isValid = false
        }
        if(!address) {
            errorProps.msg += t`error_fill_address` + '  '
            errorProps.address = true
            isValid = false
        }
        else if(address.length <= 4){
            errorProps.msg += t`error_address_length` + '  '
            errorProps.address = true
            isValid = false
        }
        if(!phoneNumber){
            errorProps.msg += t`error_phone_number` + '  '
            errorProps.phoneNumber = true
            isValid = false
        }
        else if (phoneNumber.length !== 8){
            errorProps.msg += t`error_phone_number_length` + '  '
            errorProps.phoneNumber = true
            isValid = false
        }
        if(!cookies.cart && !cookies.i_cart){
            if(!cookies.cart.length && !cookies.i_cart.length){
                errorProps.msg += t`error_cart_empty`
                errorProps.cart = true
                isValid = false
            }
        }
        setErrorMsg({...errorProps})
        return isValid
    }

    const handleOrder = () => {
        if(validateOrderForm()){
            if(payment === 'cash'){
                Axios.post('post-order/', {
                    'city': city,
                    'address': address,
                    'phone_number': phoneNumber,
                    'deliver_in': deliveryTime,
                    'additional': additional ? additional : '',
                    'payment': payment,
                    'cart': cookies.cart ? cookies.cart : {},
                    'i_cart': cookies.i_cart ? cookies.i_cart : {},
                    'auth': cookies.auth ? cookies.auth : ''
                }).then(res => {
                    if(res.data.success) {
                        setCookie('cart', {})
                        setCookie('i_cart', {})
                        alert(t`success_order_msg`)
                        window.location.reload();
                    }
                    else alert(t`failed_order_msg` + ` (${props.props.configs.contact_number})`)
                }).catch(() => {
                    alert(t`failed_order_msg` + ` (${props.props.configs.contact_number})`)
                })
            }
        }
    }

    return <Modal
                open={props.checkoutModal}
                onClose={() => props.setCheckoutModal(false)}
            >
                <div className={'checkout-modal'}>
                    <div className={'user-modal-header text-right m-3'}>
                        <CloseIcon
                            sx={{ fontSize: 40, color: '#00000080' }}
                            onClick={() => props.setCheckoutModal(false)}
                            className={'cursor-pointer'}
                        />
                    </div>
                    <p className={'text-center h5'}>{t`checkout`}</p>
                    <div className={'checkout-modal-body'}>
                        <table className="table table-striped table-responsive d-md-table">
                            <thead className={'thead-dark'}>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{t`image`}</th>
                                <th scope="col">{t`name`}</th>
                                <th scope="col">{t`price`}</th>
                                <th scope="col">{t`qty`}</th>
                                <th scope="col">{t`remove`}</th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    props.cartProducts && props.cartProducts.map((cartProduct) => {
                                        return  <tr key={JSON.stringify(cartProduct)}>
                                            <td>{cartProduct.pk}</td>
                                            <td><img alt={'image'} width={'50px'} src={`${Axios.defaults.baseURL}${cartProduct.image1.substring(1)}`} /></td>
                                            <td className={'pt-3'}>{cartProduct[`name_${i18n.language}`]}</td>
                                            <td className={'pt-3'}>{cartProduct.discounted_price ? Math.round(cartProduct.discounted_price) : Math.round(cartProduct.price)}{t`amd`}</td>
                                            <td className={'pt-3'}>
                                                <div className={'number-button-wrapper'}>
                                                    <button type="button" className={'decrement'} onClick={() => props.addToCart(cartProduct.pk, -1, 'cart')}>
                                                        <RemoveIcon />
                                                    </button>
                                                    <span>{cookies.cart[cartProduct.pk]}</span>
                                                    <button type="button" className={'increment'} onClick={() => props.addToCart(cartProduct.pk, 1, 'cart')}>
                                                        <AddIcon />
                                                    </button>
                                                </div>
                                            </td>
                                            <td className={'pt-3'}>
                                                <CloseIcon
                                                    className={'cursor-pointer remove-btn'}
                                                    sx={{ color: '#b5b5b5' }}
                                                    onClick={() => props.removeCart(cartProduct.pk, 'cart')}
                                                />
                                            </td>
                                        </tr>
                                    })
                                }
                                {
                                    props.importedCartProducts && props.importedCartProducts.map((importedCartProduct) => {
                                        return  <tr key={JSON.stringify(importedCartProduct)}>
                                            <td>{importedCartProduct.pk}</td>
                                            <td><img alt={'image'} width={'50px'} src={noImage} /></td>
                                            <td className={''}>{importedCartProduct.name}</td>
                                            <td className={''}>{importedCartProduct.discounted_price ? Math.round(importedCartProduct.discounted_price) : Math.round(importedCartProduct.price)}{t`amd`}</td>
                                            <td className={''}>
                                                <div className={'number-button-wrapper'}>
                                                    <button type="button" className={'decrement'} onClick={() => props.addToCart(importedCartProduct.pk, -1, 'i_cart')}>
                                                        <RemoveIcon />
                                                    </button>
                                                    <span>{cookies.i_cart[importedCartProduct.pk]}</span>
                                                    <button type="button" className={'increment'} onClick={() => props.addToCart(importedCartProduct.pk, 1, 'i_cart')}>
                                                        <AddIcon />
                                                    </button>
                                                </div>
                                            </td>
                                            <td className={'pt-3'}>
                                                <CloseIcon
                                                    className={'cursor-pointer remove-btn'}
                                                    sx={{ color: '#b5b5b5' }}
                                                    onClick={() => props.removeCart(importedCartProduct.pk, 'i_cart')}
                                                />
                                            </td>
                                        </tr>
                                    })
                                }
                                <tr className={'text-right'}>
                                    <td colSpan={6}>{t`price`}: <b>{Math.round(props.totalPrice)}{t`amd`}</b></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={'container-fluid'}>
                            {errorMsg.msg && errorMsg.msg.split("  ").map((msg) => {
                                return <p key={msg} className={'m-0 h6'}><FiberManualRecordIcon sx={{ fontSize: 13 }} /><span className={'text-danger'}>{msg}</span></p>
                            })}
                        </div>
                        <div className={'container-fluid checkout-form'}>
                            <div className={'row'}>
                                { !cookies.auth &&
                                    <div className={'col-6'}>
                                        <div className="form-group">
                                            <label>{t`city`} {errorMsg.city ? <span className={'text-danger'}>*</span> :
                                                <span>*</span>}</label>
                                            <select
                                                className="form-control"
                                                value={city}
                                                onChange={(e) => {
                                                    if (errorMsg.city) {
                                                        errorMsg.city = false
                                                        setErrorMsg({...errorMsg})
                                                    }
                                                    setCity(e.target.value)
                                                }}
                                            >
                                                <option></option>
                                                {
                                                    availableCities && availableCities.map(availableCity => {
                                                        return <option key={JSON.stringify(availableCity)}
                                                                       value={availableCity.pk}>
                                                            {availableCity.fields.city_hy} ({Math.round(availableCity.fields.delivery_price)}{t`amd`})
                                                        </option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                }
                                <div className={'col-6'}>
                                    <div className="form-group">
                                        <label>{t`address`} {errorMsg.address ? <span className={'text-danger'}>*</span> : <span>*</span>}</label>
                                        <input
                                            className={'form-control'}
                                            value={address}
                                            maxLength={100}
                                            onChange={(e) => {
                                                if(errorMsg.address){
                                                    errorMsg.address = false
                                                    setErrorMsg({...errorMsg})
                                                }
                                                setAddress(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={'col-6'}>
                                    <label>{t`phone_number`} {errorMsg.phoneNumber ? <span className={'text-danger'}>*</span> : <span>*</span>}</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="phone-number">+374</span>
                                        </div>
                                        <input
                                            className={'form-control'}
                                            aria-describedby="phone-number"
                                            minLength={8}
                                            maxLength={8}
                                            value={phoneNumber}
                                            onChange={(e) => {
                                                if(errorMsg.phoneNumber){
                                                    errorMsg.phoneNumber = false
                                                    setErrorMsg({...errorMsg})
                                                }
                                                setPhoneNumber(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                {
                                    !asap ?
                                        <div className={'col-6'}>
                                            <div className="mt-3 mt-md-0">
                                                <label>{t`date_time`}</label>
                                                <div>
                                                    <DateTimePicker
                                                        locale={i18n.language}
                                                        selected={deliveryTime}
                                                        value={deliveryTime}
                                                        onChange={dateTime => setDeliveryTime(dateTime)}
                                                        onClick={() => {
                                                            document.getElementsByClassName('react-calendar__navigation__label')[0].disabled = true
                                                        }}
                                                        minDate={new Date()}
                                                        calendarIcon={null}
                                                        clearIcon={null}
                                                    />
                                                    <CloseIcon className={'ml-3 cursor-pointer'} onClick={() => setAsap(true)} />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className={'col-6'}>
                                            <div className="mt-3 mt-md-0">
                                                <label>{t`asap`}</label>
                                                <p
                                                    className={'text-center cursor-pointer'}
                                                    onClick={(e) => setAsap(false)}
                                                >
                                                    <CheckBoxIcon sx={{ fontSize: 30 }} />
                                                </p>
                                            </div>
                                        </div>
                                }

                                <div className={'col-12 mt-3'}>
                                    <div className="form-group">
                                        <label>{t`additional_information`}</label>
                                        <textarea className={'form-control'} value={additional} onChange={(e) => setAdditional(e.target.value)} />
                                    </div>
                                </div>
                                <div className={'col-12'}>
                                    {
                                        totalWithDelivery &&
                                        <span>
                                            {
                                                cookies.user ?
                                                    <p>
                                                        {cookies.user.discount_in_percent && <p>{t`discount`}: -{cookies.user.discount_in_percent}%</p>}
                                                        {cookies.user.delivery_price && <p>{t`delivery_price`}: {Math.round(cookies.user.delivery_price)}{t`amd`}</p>}
                                                        <p>{t`total_cost`}: {Math.round(totalWithDelivery)}{t`amd`}</p>
                                                    </p>
                                                :
                                                    <span>{t`total_with_delivery`}: <b>{Math.round(totalWithDelivery)}{t`amd`}</b></span>

                                            }
                                        </span>
                                    }
                                </div>
                                <div className={'col-12'}>
                                    <small className={'text-secondary font-italic'}>{props.props.configs && props.props.configs[`order_conditions_${i18n.language}`]}</small>
                                </div>
                                <div className={'col-12'}>
                                    <div className={'d-flex justify-content-end mb-3 max-w-320'}>
                                        <div className={'p-0 m-0 mr-2 text-center max-w-50'}>
                                            <div className={`payment-method-container cursor-pointer ${payment === 'cash' ? 'payment-method-container-selected' : ''}`}>
                                                <img alt={'cash'} height={'28px'} src={imgCash} />
                                            </div>
                                        </div>
                                        <div className={'p-0 m-0 mr-2 text-center max-w-100'}>
                                            <div className={'payment-method-container'}>
                                                <img alt={'idram'} height={'28px'} src={imgIdram} />
                                            </div>
                                        </div>
                                        <div className={'p-0 m-0 text-center max-w-100'}>
                                            <div className={'payment-method-container'}>
                                                <img alt={'visa-master-arca'} height={'28px'} src={imgVisaMasterArca} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'col-12 mb-3 text-right'}>
                                    <button className={'btn btn-outline-secondary mr-3'} onClick={() => props.setCheckoutModal(false)}>{t`close`}</button>
                                    <button className={'btn btn-outline-dark'} onClick={() => handleOrder()}>{t`order`}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </Modal>
}
