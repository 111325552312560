import React, {useState, useEffect} from "react"
import Axios from '../../helpers/Axios';

import { InputGroup, FormControl, Table } from 'react-bootstrap';

import Header from '../../components/Header'


export default function SearchProducts(props) {
    const [code, setCode] = useState('')
    const [name, setName] = useState('')
    const [barcode, setBarcode] = useState('')
    const [products, setProducts] = useState([])
    const [filteredProducts, setFilteredProducts] = useState([])

    useEffect( () => {
        Axios.get('importedproducts/fiend_products/').then(res => {
            setProducts(res.data.products)
        })
    }, [])

    useEffect(() => {
        const searchHandle = () => {
            setFilteredProducts([])
            let filterProducts = []
            for (const product of products) {
                if(code){
                    if (product.code.search(code) >= 0){
                        filterProducts.push(product)
                    }
                }
                if(name){
                    if (product.name.search(name) >= 0){
                        filterProducts.push(product)
                    }
                }
                if(barcode){
                    if (product.barcode.search(barcode) >= 0){
                        filterProducts.push(product)
                    }
                }
            }
            setFilteredProducts(filterProducts)
        }
        searchHandle()
    }, [code, name, barcode])


    return (
        <div className={'container-fluid m-0 p-0'}>
            <Header props={props} />
            <div className={'row m-0'}>
                <div className={'col'}> </div>
                <div className={'col, text-center'}>
                    <p>__</p>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Search</InputGroup.Text>
                        <FormControl
                            onChange={(e) => setCode(e.target.value)}
                            placeholder="Code"
                            aria-label="Code"
                        />
                        <FormControl
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                            aria-label="Name"
                        />
                        <FormControl
                            onChange={(e) => setBarcode(e.target.value)}
                            placeholder="Barcode"
                            aria-label="Barcode"
                        />
                    </InputGroup>
                </div>
                <div className={'col'}> </div>
            </div>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Code</th>
                    <th>Name</th>
                    <th>price</th>
                    <th>Barcode</th>
                </tr>
                </thead>
                <tbody>
                    {filteredProducts.map((product, index) => {
                        return <tr key={index}>
                                    <td>{product.code}</td>
                                    <td>{product.name}</td>
                                    <td>{product.price}</td>
                                    <td>{product.barcode}</td>
                               </tr>
                    })}
                </tbody>
            </Table>
        </div>
    );
}
