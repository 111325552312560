import React from "react"

import './style.css'
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import {useTranslation} from "react-i18next"

export default function AboutUs(props) {
    const { i18n, t } = useTranslation();

    return (
        <div className={'container-fluid m-0 p-0'}>
            <Header props={props} />
            <div className={'container-fluid contact'}>
                <div className={'about-us-container'}>
                    <div className={'about-us-text-wrapper'}>
                    </div>
                </div>
                <div className={'container-fluid'}>
                    <div className={'row mb-3'}>
                        <div className={'col-md-12 col-lg-6 mt-4 mb-4'}>
                            <div className={'container contact-info-section'}>
                                <div className={'row'}>
                                    <div className={'col-4 p-0'}>
                                        <h5>{ t`tel` }</h5>
                                    </div>
                                    <div className={'col-8 p-0'}>
                                        {props.configs && <p>{props.configs.contact_number}</p>}
                                        {props.configs && <p>{props.configs.contact_number_second}</p>}
                                    </div>
                                    <div className={'col-4 p-0'}>
                                        <h5>{t`address`}:</h5>
                                    </div>
                                    <div className={'col-8 p-0 '} style={{'minWidth': '215px'}}>
                                        {props.configs && <p>{props.configs[`address_${i18n.language}`]}</p>}
                                        {props.configs && <p>{props.configs[`address_second_${i18n.language}`]}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'col-md-12 col-lg-6 mb-4'}>
                            {
                                props.configs &&
                                <iframe
                                    src={props.configs.address_map_link}
                                    script-src={'none'}
                                    title={'map'}
                                    className={'map'}
                                    loading="lazy"
                                ></iframe>
                            }
                            {
                                props.configs && props.configs.address_map_link_second != '0' ?
                                <iframe
                                    src={props.configs.address_map_link_second}
                                    script-src={'none'}
                                    title={'map'}
                                    className={'map'}
                                    loading="lazy"
                                ></iframe> : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer props={props} />
        </div>
    )
}
