import React, {useEffect, useState} from "react"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

import './style.css'
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import circuitBoard from '../../media/circuitboard.png'
import circuitBoardTablet from '../../media/circuitboard-tablet.png'
import circuitBoardMobile from '../../media/circuitboard-mobile.png'
import onlineShopping from '../../media/online-shop-shopping.png'
import Axios from "../../helpers/Axios";
import {useHistory} from "react-router-dom";

export default function Home(props) {
    const [partners, setPartners] = useState(null)
    const [clients, setClients] = useState(null)

    const { i18n, t } = useTranslation();
    const history = useHistory();

    const slick_settings = {
        infinite: true,
        centerPadding: "10px",
        slidesToShow: 4,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 500,
        cssEase: "linear",
        swipeToSlide: true,
        pauseOnHover: true,
        arrows: false
    }
    const slick_settings_responsive = [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 550,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
    const settings_right = {
        slidesToScroll: -1,
        ...slick_settings,
        responsive: slick_settings_responsive,
    };

    const settings_left = {
        slidesToScroll: 1,
        ...slick_settings,
        responsive: slick_settings_responsive
    };

    useEffect(() => {
        Axios.get('get-partners-clients').then(res => {
            setPartners(JSON.parse(res.data.partners))
            setClients(JSON.parse(res.data.clients))
        })
    }, [])


    return (
        <div className={'container-fluid m-0 p-0 home'}>
            <Header props={props} />
            <div className={'top-img-wrapper border-bottom border-dark-50 border-2'}>
                {props.pageWidth >= 1000 ? <img alt={'logo'} className={'top-img'} src={circuitBoard}/> : ''}
                {props.pageWidth < 1000 && props.pageWidth >= 500 ? <img alt={'logo'} className={'top-img'} src={circuitBoardTablet}/> : ''}
                {props.pageWidth < 500 ? <img alt={'logo'} className={'top-img'} src={circuitBoardMobile}/> : ''}
                <img alt={'online-shopping-img'} src={onlineShopping} className={'online-shopping-img'} onClick={() => history.push('shop')}/>
            </div>
            <div className={'p-4 '}>
                <p className={'text-center home-main-text font-weight-bold h6 text-shadow'}>{props.configs && props.configs[`welcome_text_${i18n.language}`]}</p>
            </div>
            <div className={'parallax-parent'}>
                <div className={'parallax'}>
                    <div className={'parallax-text'}>
                        <div style={{width: '100%'}} className={'container-fluid pb-5 parallax-text-border'}>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'container-fluid'}>
                {
                    partners && partners.length ?
                    <>
                        <p className={'mt-3 h5'}><b>{t`our_partners`}</b></p>
                        <Slider {...settings_right}>
                            {
                                partners.map(partner => {
                                    return <div key={JSON.stringify(partner)}>
                                        <img alt={''} className={'cursor-pointer'} width={'250px'} src={`${Axios.defaults.baseURL}media/${partner.fields.logo}`} onClick={()=> window.open('https://www.samsung.com/us/', "_blank")} />
                                    </div>
                                })
                            }
                        </Slider>
                    </> : ''
                }
                {
                    clients && clients.length ?
                    <>
                        <p className={'mt-3 h5'}><b>{t`our_clients`}</b></p>
                        <Slider {...settings_left}>
                        {
                            clients.map(client => {
                                return <div key={JSON.stringify(client)}>
                                    <img alt={''} className={'cursor-pointer'} width={'250px'} src={`${Axios.defaults.baseURL}media/${client.fields.logo}`} onClick={()=> window.open('https://www.samsung.com/us/', "_blank")} />
                                </div>
                            })
                        }
                        </Slider>
                    </> : ''
                }
            </div>
            <Footer props={props} />
       </div>
    )
}
