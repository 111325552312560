import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en from './en.json'
import ru from './ru.json'
import hy from './hy.json'

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            hy: {
                translations: hy
            },
            en: {
                translations: en
            },
            ru: {
                translations: ru
            }
        },
        lng:  localStorage.getItem('i18nextLng') || 'hy',
        fallbackLng: "hy",
        debug: false,
        ns: ["translations"],
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
