import axios from 'axios'

const baseURL = 'https://a.stepanyan.technology/api/v1/'

const Axios = axios.create();

Axios.defaults.baseURL = baseURL

Axios.defaults.headers = { 'Content-Type': 'application/json' }

//All request will wait 20 seconds before timeout
Axios.defaults.timeout = 20000

export default Axios
