import React, {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import {useTranslation} from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import {Modal} from "@mui/material";
import './style.css'
import userIcon from '../../media/user.svg'
import Axios from "../../helpers/Axios";

export default function UserModal(props){
    const [cookies, setCookie, removeCookie] = useCookies()



    const { i18n, t } = useTranslation()

    const handleLogOut = () => {
        removeCookie('auth')
        removeCookie('user')
        window.location.reload(false);
    }

    return cookies.user ? <Modal
                open={props.userModal}
                onClose={() => props.setUserModal(false)}
            >
                <div className={'user-modal'}>
                    <div className={'user-modal-header text-right m-3'}>
                        <CloseIcon
                            sx={{ fontSize: 40, color: '#00000080' }}
                            onClick={() => props.setUserModal(false)}
                            className={'cursor-pointer'}
                        />
                    </div>
                    <div className={'user-modal-body'}>
                        <div className={'text-center'}>
                            <img width={200} alt={"user"} src={userIcon}/>
                            <div className={'row text-left m-5 h6'}>
                                <div className={'col-6'}>
                                    {t`name`}:
                                </div>
                                <div className={'col-6'}>
                                    <p>{cookies.user.name}</p>
                                </div>
                                <div className={'col-6'}>
                                    {t`tel`}
                                </div>
                                <div className={'col-6'}>
                                    <p>{cookies.user.phone_number}</p>
                                </div>
                                <div className={'col-6'}>
                                    {t`address`}:
                                </div>
                                <div className={'col-6'}>
                                    <p>{cookies.user.address}</p>
                                </div>
                                <div className={'col-12 mt-3 d-flex justify-content-end'}>
                                    <button className={'btn btn-outline-secondary mr-3'} onClick={() => props.setUserModal(false)}>
                                        {t`close`}
                                    </button>
                                    <button className={'btn btn-outline-dark mr-1'} onClick={() => handleLogOut()}>
                                        {t`log_out`}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Modal> : ''
}
