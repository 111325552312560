import React, {useEffect, useRef, useState} from "react";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import userIcon from "../../media/user.svg";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {useCookies} from "react-cookie";
import {useTranslation} from "react-i18next";
import './style.css'

export default function ShopTopBar(props){
    const [topBarToggle, setTopBarToggle] = useState(window.innerWidth <= 1000)

    const [cookies, setCookie, removeCookie] = useCookies()

    const cartRef = useRef()

    const { i18n, t } = useTranslation()

    window.addEventListener('resize', () => setTopBarToggle(window.innerWidth <= 1000))
    window.addEventListener('scroll', () => setTopBarToggle(window.innerWidth <= 1000))

    const handleSortProducts = (sort) => {
        if(props.sortProducts === sort){
            props.setSortProducts(0)
        } else{
            props.setSortProducts(sort)
        }
    }

    useEffect(() => {
        cartRef.current.className ='d-grid'
        setTimeout(() => {
            cartRef.current.className ='d-grid shopping-basket-effect'
        }, 10)
    }, [props.cartQty])


    return <div>
        <div className={`container-fluid p-0 m-0 products-top-bar ${topBarToggle? '' : 'hide'}`}>
            <div className={'row p-3 border-bottom'}>
                <div className={'col-sm col-12 mb-md-0 menu-sort-container'}>
                    <div className={'text-center menu-sort-container'}>
                        <p className={'cursor-pointer mb-0'} onClick={() => setTopBarToggle(false)}><KeyboardArrowDownIcon sx={{ fontSize: 50 }} /></p>
                    </div>
                </div>
            </div>
        </div>
        <div id={'products-top-bar'} className={`container-fluid p-0 m-0 products-top-bar ${topBarToggle? 'hide' : ''}`}>
            <div className={'row p-3 border-bottom'}>
                <div className={'col-sm col-12 mb-3 mb-md-0 menu-sort-container'}>
                    <div className={'text-center menu-sort-container'}>
                    <span
                        id={'products-top-bar'}
                        className={`p-2 open-menu-btn cursor-pointer ${props.toggleDropdownProductsMenu? 'border-dark': ''}`}
                        onClick={() => {
                            props.setToggleDropdownProductsMenu(!props.toggleDropdownProductsMenu)
                        }}
                    >
                        <ViewModuleIcon sx={{ fontSize: 40 }} className={'view-module'} />
                        <span className={'ml-2 text'}> <b> { t`all` }</b></span>
                    </span>
                        <span className={'ml-5 sort-arrow cursor-pointer'} onClick={() => handleSortProducts(1)}>
                        <ArrowUpwardIcon color={`${props.sortProducts === 1 ? 'primary': ''}`} className={'view-module'} />
                    </span>
                        <span className={'ml-1 sort-arrow cursor-pointer'} onClick={() => handleSortProducts(-1)}>
                        <ArrowDownwardIcon color={`${props.sortProducts === -1 ? 'primary': ''}`} className={'view-module'} />
                    </span>
                    </div>
                </div>
                <div className={'col-md-5 col-xl col-12 mb-3 mb-md-0'}>
                    <div
                        onClick={() => props.setShowPopover(false)}
                        className={`popover-container cursor-pointer ${props.showPopover ? '' : 'hide'}`}>
                        <div className={'text-center'}>
                         <span className={'popover-arrow'}>
                            <ArrowDropUpIcon sx={{ fontSize: 50 }} />
                        </span>
                            <div className={'text-left m-2'}>
                                <p>{t`success_client_account_enter`}</p>
                            </div>
                        </div>
                    </div>
                    <div className="input-group search-container">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t`search`}
                            aria-label="{{t`search`}}"
                            onChange={(e) => props.setSearch(e.target.value)}
                            value={props.search}
                        />
                        <div className="input-group-append">
                            <span className="input-group-text"><SearchIcon /></span>
                        </div>
                    </div>
                </div>
                <div className={'col min-400 p-0 m-0'}>
                    <div className={'text-center cart-container'}>
                        <div className={'container-fluid m-0 p-0'}>
                            <div className={'row'}>
                                <div className={'col'}>
                                    <span className={'text-center user-container cursor-pointer'} onClick={() => props.setUserModal(!props.userModal)}>
                                        {
                                            cookies.user && <span>
                                                <img width={30} alt={"user"} src={userIcon}/>
                                                <span className={'user-data'}>
                                                    <span>{cookies.user.name}</span>
                                                </span>
                                            </span>
                                        }
                                    </span>
                                </div>
                                <div className={'col'}>
                                <span className={'d-table'} ref={cartRef}>
                                     <span
                                         className={'shopping-basket cursor-pointer'}
                                         onClick={() => props.setCheckoutModal(true)}
                                     >
                                        <ShoppingCartIcon sx={{ fontSize: 30 }} />
                                         {props.cartQty ? <span className="badge badge-dark text-center">&nbsp;<b>{props.cartQty}</b></span> : ''}
                                    </span>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                !props.search && <div
                    className={`container-fluid m-0 p-0 border-bottom subcategory-section ${props.locationParam.get('cat') ? '' : 'hide'}`}>
                    <div className={'container m-1'}>
                        {
                            !!props.selectedCategory && props.getSelectedCategory() &&
                            <p className={'mb-0'}>
                                <span className={'p-2 m-2 badge cursor-default badge-dark'}>
                                    <b>
                                        {props.getSelectedCategory().fields[`name_${i18n.language}`]}
                                    </b>
                                </span>
                                <span
                                    className={'p-2 m-2 badge badge-pill cursor-pointer badge-warning'}
                                    onClick={() => {
                                        props.setSelectedCategory('')
                                        props.setSelectedSubcategory('')
                                    }}
                                >
                                    {t`remove_filters`} <FilterAltOffIcon sx={{fontSize: 15}}/>
                                </span>
                                {
                                    !!props.sortProducts && <span
                                        className={'p-2 m-1 badge badge-pill cursor-pointer badge-warning'}
                                        onClick={() => props.setSortProducts(0)}
                                    >
                                        {t`remove_sort`} <FilterListOffIcon sx={{fontSize: 15}}/>
                                    </span>
                                }
                            </p>
                        }
                        {
                            props.subcategories && props.subcategories.map((subcategory, index) => {
                                if (subcategory.fields.category === props.selectedCategory) {
                                    return <span
                                        onClick={() => subcategory.pk === props.selectedSubcategory ? props.setSelectedSubcategory(null) : props.setSelectedSubcategory(subcategory.pk)}
                                        className={`p-2 mr-2 ml-2 mb-2 badge badge-pill cursor-pointer ${subcategory.pk === props.selectedSubcategory ? 'badge-dark' : 'badge-secondary'}`}
                                        key={index}
                                    >
                                            <b>
                                                {subcategory.fields[`name_${i18n.language}`]}
                                            </b>
                                       </span>
                                }
                                return ''
                            })
                        }
                    </div>
                </div>
            }
            <p
                className={`cursor-pointer border-bottom mb-0 text-center ${window.innerWidth <= 1000 ? '' : 'hide'}`}
                onClick={() => setTopBarToggle(true)}>
                <KeyboardArrowUpIcon sx={{ fontSize: 50 }} />
            </p>
        </div>
    </div>
}
