import React from 'react';
import ReactDOM from 'react-dom';
import "./i18n/i18n";
import App from "./App";
import './index.css'

ReactDOM.render(
    <App />,
    document.getElementById("root")
)
