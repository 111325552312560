import React,  {useState, useEffect} from "react"
import {BrowserRouter, Route} from "react-router-dom"
import {useTranslation} from "react-i18next"

import {handlePreloader} from './helpers/Utils'
import Home from "./pages/Home"
import SearchProducts from "./pages/SearchProducts"
import AboutUs from "./pages/AboutUs"
import Client from "./pages/Client"
import Shop from "./pages/Shop"
import './App.css'
import {useCookies} from "react-cookie";
import Product from "./pages/Product";
import Axios from "./helpers/Axios";
import TawkTo from 'tawkto-react'

function App() {
    const [pageWidth, setPageWidth] = useState(null)
    const [configs, setConfigs] = useState(null)

    // let tawk = new TawkTo('623c90eb0bfe3f4a876f89ff', '1fuu9c6gg')

    const [cookies, setCookie, removeCookie] = useCookies()

    const { i18n } = useTranslation();

    //  First load
    useEffect(() => {
        setPageWidth(window.innerWidth)
        window.addEventListener("resize", () => {
            setPageWidth(window.innerWidth)
        });
        document.querySelector('html').lang = i18n.language
        handlePreloader(1000)
        removeCookie('allowResetPagination')
        Axios.get('get-configs').then(res => {
            setConfigs(JSON.parse(res.data.config)[0].fields)
        })
    }, [])


    return (
        <div id={'App'}>
            <BrowserRouter>
                <Route exact path="/">
                    <Home configs={configs} pageWidth={pageWidth} />
                </Route>
                <Route path="/shop">
                    <Shop configs={configs} pageWidth={pageWidth} />
                </Route>
                <Route path="/product">
                    <Product configs={configs} pageWidth={pageWidth} />
                </Route>
                <Route path="/contact_us">
                    <AboutUs configs={configs} pageWidth={pageWidth} />
                </Route>
                <Route path="/search">
                    <SearchProducts />
                </Route>
                <Route path="/client">
                    <Client />
                </Route>
            </BrowserRouter>
        </div>
    );
}

export default App;
