import React, {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import Axios from "../../helpers/Axios";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Header from "../../components/Header";
import './style.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Slider from 'react-slick'
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Footer from "../../components/Footer";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CheckoutModal from "../../components/CheckoutModal";
import '../../static/styles.min.css';
import InnerImageZoom from 'react-inner-image-zoom';
import parse from 'html-react-parser';
import noImage from '../../media/no-image.png'
import basketPNG from "../../media/basket.png";
export default function Product(props){
    window.addEventListener('scroll',function(e) {
        try{
            if(document.documentElement.scrollTop > 75){
                document.querySelector('#shopping-basket-flex').style.top = '10px'
            } else {
                document.querySelector('#shopping-basket-flex').style.top = '75px'
            }
        } catch (e){}
    })

    const [cartQty, setCartQty] = useState(0)
    const [products, setProducts] = useState([])
    const [importedProducts, setImportedProducts] = useState([])
    const [cartProducts, setCartProducts] = useState([])
    const [importedCartProducts, setImportedCartProducts] = useState([])
    const [totalPrice, setTotalPrice] = useState()
    const [checkoutModal, setCheckoutModal] = useState(false)
    const [product, setProduct] = useState()
    const [suggestedProducts, setSuggestedProducts] = useState()
    const [currentProductCartPrice, setCurrentProductCartPrice] = useState()

    const [cookies, setCookie, removeCookie] = useCookies()

    let locationParam = {
        get: (key) => (new URL(document.location)).searchParams.get(key),
        set: (key, value) => {
            let url = new URL(document.location.href)
            url.searchParams.set(key, value)
            history.push(`${url.pathname}${url.search}`)
        }
    }

    const history = useHistory();
    const { i18n, t } = useTranslation()

    const settings = {
        customPaging: (i) => {
            if(product['image'+(i+1)]) return <a><img width={'45px'} src={`${Axios.defaults.baseURL}${product['image'+(i+1)].substring(1)}`} /></a>
        },
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    }

    const suggested_settings = {
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: suggestedProducts ? suggestedProducts.length >= 4 ? 4 : suggestedProducts.length : 1,
        slidesToScroll: suggestedProducts ? suggestedProducts.length >= 4 ? 4 : suggestedProducts.length : 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: suggestedProducts ? suggestedProducts.length >= 3 ? 3 : suggestedProducts.length : 1,
                    slidesToScroll: suggestedProducts ? suggestedProducts.length >= 3 ? 3 : suggestedProducts.length : 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: suggestedProducts ? suggestedProducts.length >= 2 ? 2 : suggestedProducts.length : 1,
                    slidesToScroll: suggestedProducts ? suggestedProducts.length >= 2 ? 2 : suggestedProducts.length : 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    }

    useEffect(() => {
        if(localStorage.getItem('products')){
            let data = JSON.parse(localStorage.getItem('products'))
            setProducts(data.products)
            setImportedProducts(data.imported_products)
        }
        getProduct()
        countCartQty()
    }, [])

    useEffect(() => removeCookie('allowResetPagination'), [])
    useEffect(() => productsInCart(), [products, importedProducts])

    useEffect(() => {
        if(product && product.imported && cookies['i_cart']){
            let cart = cookies['i_cart']
            Object.entries(cart).map(([key, value]) => {
                if(+key === product.pk){
                    setCurrentProductCartPrice(product.discounted_price ? product.discounted_price * +value : product.price * +value)
                }
            })
        } else if(product && cookies['cart']){
            let cart = cookies['cart']
            Object.entries(cart).map(([key, value]) => {
                if(+key === product.pk){
                    setCurrentProductCartPrice(product.discounted_price ? product.discounted_price * +value : product.price * +value)
                }
            })
        }
    }, [cookies, product])

    const getProduct = () => {
        Axios.get('get-products/0', {params : cookies.auth}).then(res => {
            setProducts(res.data.products)
            setImportedProducts(res.data.imported_products)
        }).catch(() => {
            setTimeout((e) => { if(e){getProduct()} }, 10000)
        })
        if(locationParam.get('prod')){
            Axios.get(`get-product/${locationParam.get('prod')}`).then(res => {
                setProduct(res.data.product)
                if(res.data.suggested_products) setSuggestedProducts(JSON.parse(res.data.suggested_products))
            }).catch(function () {
                alert(t`something_went_wrong_reload`)
            });
        } else if(locationParam.get('i_prod')){
            Axios.get(`get-imported-product/${locationParam.get('i_prod')}`, {params : cookies.auth}).then(res => {
                if(res.data.success){
                    setProduct(res.data.product)
                    if(res.data.suggested_products) setSuggestedProducts(JSON.parse(res.data.suggested_products))
                } else {
                    alert(t`something_went_wrong_reload`)
                }
            }).catch(function () {
                alert(t`something_went_wrong_reload`)
            });
        }
    }

    const addToCart = (pk, qty, name) => {
        if(cookies[name]){
            let cart = cookies[name]
            if(cart[pk]){
                if(cart[pk] === 1 && qty < 1){
                    delete cart[pk]
                } else{
                    if(cart[pk] < 99) cart[pk] = cart[pk] + qty
                    else if(qty < 0) cart[pk] = cart[pk] + qty
                }
            } else{
                cart[pk] = qty
            }
            setCookie(name, cart)
        } else {
            let cart = {}
            cart[pk] = qty
            setCookie(name, cart)
        }
        countCartQty()
    }

    const removeCart = (pk, name) => {
        if(cookies[name]){
            let cart = cookies[name]
            delete cart[pk]
            setCookie(name, cart)
        }
        countCartQty()
    }

    const productsInCart = () => {
        let total = 0
        if(products && cookies.cart){
            let tempCartProducts = []
            for (let cart_item in cookies.cart) {
                for (let product in products){
                    if(products[product].pk === +cart_item){
                        tempCartProducts.push({qty: cookies.cart[cart_item], ...products[product]})
                        total += products[product].discounted_price ?
                            (+products[product].discounted_price * cookies.cart[cart_item])
                            :
                            (+products[product].price * cookies.cart[cart_item])
                    }
                }
            }
            setCartProducts(tempCartProducts)
        }
        if(importedProducts && cookies.i_cart){
            let tempImportedCartProducts = []
            for (let i_cart_item in cookies.i_cart) {
                for (let product in importedProducts){
                    if(importedProducts[product].pk === +i_cart_item){
                        tempImportedCartProducts.push({qty: cookies.i_cart[i_cart_item], ...importedProducts[product]})
                        total += importedProducts[product].discounted_price ?
                            (+importedProducts[product].discounted_price * cookies.i_cart[i_cart_item])
                            :
                            (+importedProducts[product].price * cookies.i_cart[i_cart_item])
                    }
                }
            }
            setImportedCartProducts(tempImportedCartProducts)
        }
        setTotalPrice(total)
    }

    const countCartQty = () => {
        let qty = 0
        if(cookies['cart']){
            let cart = cookies.cart
            Object.entries(cart).map(([key, value]) => {qty += value})
        }
        if(cookies['i_cart']){
            let i_cart = cookies.i_cart
            Object.entries(i_cart).map(([key, value]) => {qty += value})
        }
        setCartQty(qty)
        productsInCart()
    }

    return (
        <div className={'container-fluid m-0 p-0'}>
            <Header props={props} />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-light">
                    <li className="breadcrumb-item"><a href="#" onClick={() => history.push('/shop')}>{t`shop`}</a></li>
                    {product && <li className="breadcrumb-item active" aria-current="page">{product.name || product[`name_${i18n.language}`]}</li>}
                </ol>
            </nav>
            <div className={'container-fluid'}>
                <ArrowBackIcon className={'cursor-pointer'} onClick={() => history.goBack()} />
            </div>
            <div className={'container product-page-container'}>
                <div className={'row'}>
                    <div className={'col-12 col-sm-12 col-md-6'}>
                        {
                            product && <div className={'slider mr-4 ml-4'}>
                                <Slider {...settings}>
                                    {product.imported && <div><img src={noImage} /></div>}
                                    {product.image1 && <InnerImageZoom src={`${Axios.defaults.baseURL}${product.image1.substring(1)}`} zoomSrc={`${Axios.defaults.baseURL}${product.image1.substring(1)}`} zoomScale={1.5} />}
                                    {product.image2 && <InnerImageZoom src={`${Axios.defaults.baseURL}${product.image2.substring(1)}`} zoomSrc={`${Axios.defaults.baseURL}${product.image2.substring(1)}`} zoomScale={1.5} />}
                                    {product.image3 && <InnerImageZoom src={`${Axios.defaults.baseURL}${product.image3.substring(1)}`} zoomSrc={`${Axios.defaults.baseURL}${product.image3.substring(1)}`} zoomScale={1.5} />}
                                </Slider>
                            </div>
                        }
                    </div>
                    <div className={'col product-right-container'}>
                        {product && <p className={'h5 mt-3'}>{product.name ? product.name : product[`name_${i18n.language}`]}</p>}
                        <p>
                            {product && <span className={`${!!product.discounted_price ? 'old-price' : ''}`}>{Math.round(product.price)}{t`amd`}</span>}
                            {product && !!product.discounted_price && <span className={'discounted-price'}> {Math.round(product.discounted_price)}{t`amd`}</span>}
                        </p>
                        {
                            product && <div className={'mt-4'}>
                                {
                                    product.imported ?
                                        cookies.i_cart && cookies.i_cart[product.pk] && <div>
                                            <span className="number-button-wrapper">
                                                <button type="button" className={'decrement'} onClick={() => addToCart(product.pk, -1, 'i_cart')}>
                                                    <RemoveIcon />
                                                </button>
                                                <span>{cookies.i_cart[product.pk]}</span>
                                                <button type="button" className={'increment'} onClick={() => addToCart(product.pk, 1, 'i_cart')}>
                                                    <AddIcon />
                                                </button>
                                            </span>
                                            <p className={'mt-2'}>{t`total_cost`}: {currentProductCartPrice}{t`amd`}</p>
                                        </div>
                                        :
                                        cookies.cart && cookies.cart[product.pk] && <div>
                                            <span className="number-button-wrapper">
                                                <button type="button" className={'decrement'} onClick={() => addToCart(product.pk, -1, 'cart')}>
                                                    <RemoveIcon />
                                                </button>
                                                <span>{cookies.cart[product.pk]}</span>
                                                <button type="button" className={'increment'} onClick={() => addToCart(product.pk, 1, 'cart')}>
                                                    <AddIcon />
                                                </button>
                                            </span>
                                            <p className={'mt-2'}>{t`total_cost`}: {currentProductCartPrice}{t`amd`}</p>
                                        </div>
                                }
                                {
                                    product.imported ?
                                        (!cookies.i_cart || !cookies.i_cart[product.pk]) && <button onClick={() => addToCart(product.pk, 1, 'i_cart')} className={'add-to-cart-button'}><img width={18} alt={"basket"} src={basketPNG}/></button>
                                        :
                                        (!cookies.cart || !cookies.cart[product.pk]) && <button onClick={() => addToCart(product.pk, 1, 'cart')} className={'add-to-cart-button'}><img width={18} alt={"basket"} src={basketPNG}/></button>
                                }
                            </div>
                        }
                        <div className={'delivery-available text-center mt-5 mb-3'}>
                            <span><LocalShippingIcon /> {t`delivery_is_available_for_this_item`}</span>
                        </div>
                    </div>
                </div>
                <div className={'container description-section'}>
                    {product && !product.imported && <p>{parse(product[`description_${i18n.language}`])}</p>}
                </div>
            </div>
            <div className={'container p-0 mt-5 mb-5'}>
                <p className={'text-center'}>{t`suggested_products`}</p>
                <Slider {...suggested_settings}>
                    {
                        suggestedProducts && suggestedProducts.map(suggestedProduct => {
                            return <a
                                className={'suggested-product-container cursor-pointer'}
                                key={suggestedProduct}
                                href={product.imported ? `/product?i_prod=${suggestedProduct.pk}` : `/product?prod=${suggestedProduct.pk}`}
                            >
                                        {
                                            suggestedProduct.fields.image1 ?
                                                <img alt={suggestedProduct.fields.image1} width={'150px'} src={`${Axios.defaults.baseURL}media/${suggestedProduct.fields.image1}`}/>
                                                :
                                                <img alt={'product image'} width={'150px'} src={noImage}/>
                                        }
                                        <p className={'text-center'}>{product.imported ? suggestedProduct.fields.name : suggestedProduct.fields[`name_${i18n.language}`]}</p>
                                        <p className={'text-center'}>
                                            <span className={`${!!suggestedProduct.fields.discounted_price ? 'old-price' : ''}`}>{Math.round(suggestedProduct.fields.price)}{t`amd`}</span>
                                            {!!suggestedProduct.fields.discounted_price && <span className={'discounted-price'}> {Math.round(suggestedProduct.fields.discounted_price)}{t`amd`}</span>}
                                        </p>
                                    </a>
                        })
                    }
                </Slider>
            </div>
            <div id={'shopping-basket-flex'}>
                <span>
                     <span
                         className={'shopping-basket cursor-pointer'}
                         onClick={() => setCheckoutModal(true)}
                     >
                            <ShoppingCartIcon sx={{ fontSize: 30 }} />
                         <span className="badge badge-dark text-center">&nbsp;<b>{cartQty}</b></span>
                    </span>
                </span>
            </div>
            <CheckoutModal
                checkoutModal={checkoutModal}
                setCheckoutModal={setCheckoutModal}
                products={products}
                importedProducts={importedProducts}
                cartProducts={cartProducts}
                importedCartProducts={importedCartProducts}
                totalPrice={totalPrice}
                addToCart={addToCart}
                removeCart={removeCart}
                props={props}
            />
            <Footer props={props} />
        </div>
    )
}
