import React,  {useState, useEffect} from "react"
import Header from '../../components/Header'
import ShopCategoryDropdown from "../../components/ShopCategoryDropdown";
import {useCookies} from "react-cookie"
import {useTranslation} from "react-i18next"
import Axios from "../../helpers/Axios"
import './style.css'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {useHistory} from "react-router-dom";
import ShopTopBar from "../../components/ShopTopBar";
import {handlePreloader, pageLayout} from '../../helpers/Utils'
import {Pagination} from "@mui/material";
import Footer from "../../components/Footer";
import UserModal from "../../components/UserModal";
import CheckoutModal from "../../components/CheckoutModal";
import noImage from "../../media/no-image.png"
import basketPNG from "../../media/basket.png";

export default function Client(props) {
    let locationParam = {
        get: (key) => (new URL(document.location)).searchParams.get(key),
        set: (key, value) => {
            let url = new URL(document.location.href)
            url.searchParams.set(key, value)
            history.push(`${url.pathname}${url.search}`)
        }
    }

    const defaultShowOnPage = 30

    const [showPopover, setShowPopover] = useState(false)
    const [toggleDropdownProductsMenu, setToggleDropdownProductsMenu] = useState(false)
    const [categories, setCategories] = useState()
    const [selectedCategory, setSelectedCategory] = useState(+locationParam.get('cat'))
    const [subcategories, setSubcategories] = useState()
    const [selectedSubcategory, setSelectedSubcategory] = useState(+locationParam.get('subcat'))
    const [search, setSearch] = useState('')
    const [products, setProducts] = useState([])
    const [importedProducts, setImportedProducts] = useState([])
    const [cartQty, setCartQty] = useState(0)
    const [filteredProducts, setFilteredProducts] = useState()
    const [sortProducts, setSortProducts] = useState(locationParam.get('sort') ? +locationParam.get('sort') : 0)
    const [page, setPage] = useState(+locationParam.get('page') ? +locationParam.get('page') : 1)
    const [showOnPage, setShowOnPage] = useState(+locationParam.get('showonpage') ? +locationParam.get('showonpage') : defaultShowOnPage)
    const [cartProducts, setCartProducts] = useState([])
    const [importedCartProducts, setImportedCartProducts] = useState([])
    const [totalPrice, setTotalPrice] = useState()

    const [userModal, setUserModal] = useState(false)
    const [checkoutModal, setCheckoutModal] = useState(false)

    const [cookies, setCookie, removeCookie] = useCookies()

    const history = useHistory();

    const { i18n, t } = useTranslation()

    const resetPagination = () => {
        setPage(1)
        setShowOnPage(defaultShowOnPage)
    }

    useEffect(() => {
        if(cookies.auth){
            if(cookies.auth.auth){
                Axios.post('get-user/', cookies.auth).then(res => {
                    if(res.data.success){
                        if(!cookies.user){
                            setShowPopover(true)
                            setTimeout(() => setShowPopover(false), 10000)
                        }
                        setCookie('user', res.data)
                    } else {
                        notSuccess()
                    }
                }).catch(function (error) {
                    notSuccess()
                });
            } else {
                notSuccess()
            }
        }
        if(localStorage.getItem('products')){
            let data = JSON.parse(localStorage.getItem('products'))
            setProducts(data.products)
            setImportedProducts(data.imported_products)
        } else {
            getProducts(31)
        }
        getCategories()
        getProducts()
        countCartQty()
    }, [])

    useEffect(() => {
        if(toggleDropdownProductsMenu) document.querySelector('body').className = 'overflow-hidden'
        else document.querySelector('body').className = 'body'
    }, [toggleDropdownProductsMenu])

    useEffect(() => {
        locationParam.set('cat', selectedCategory ? selectedCategory : '')
        locationParam.set('subcat', selectedSubcategory ? selectedSubcategory : '')
        filterProducts()
    }, [selectedCategory, selectedSubcategory])

    useEffect(() => {
        filterProducts()
    }, [search])

    useEffect(() => {
        locationParam.set('page', page ? page : '')
        locationParam.set('showonpage', showOnPage ? showOnPage: '')
    }, [page, showOnPage])

    useEffect(() => {
        locationParam.set('sort', sortProducts ? +sortProducts : '')
        handleSortProducts()
    }, [sortProducts])

    useEffect(() => {
        filterProducts()
        productsInCart()
    }, [products, importedProducts])
    useEffect(() => handleSortProducts(), [sortProducts])
    useEffect(() => window.scrollTo({top: 101, behavior: 'smooth'}), [page, showOnPage])

    const handleSortProducts = (prouducts=null) => {
        if(filteredProducts){
            handlePreloader(250)
            let tempFilteredProducts = prouducts ? prouducts : JSON.parse(JSON.stringify(filteredProducts))
            setFilteredProducts([])
            if(sortProducts === 1){
                tempFilteredProducts.sort(function(a, b) {
                    return getPriceFromObject(a) - getPriceFromObject(b)
                })
                setFilteredProducts(tempFilteredProducts)
            } else if(sortProducts === -1){
                tempFilteredProducts.sort(function(a, b) {
                    return getPriceFromObject(b) - getPriceFromObject(a)
                })
                setFilteredProducts(tempFilteredProducts)
            } else {
                filterProducts()
            }
        }
    }

    const filterProducts = () => {
        if(products){
            if(cookies.allowResetPagination) resetPagination()
            let tempFilteredProducts = []
            if(search){
                for (let index in filteredProducts){
                    console.log(filteredProducts[index])
                    if(filteredProducts[index].name){
                        if(`${filteredProducts[index].name.toLowerCase()}`.search(search.toLowerCase()) >= 0) tempFilteredProducts.push(filteredProducts[index])
                    } else {
                        if(`${filteredProducts[index].name_hy.toLowerCase()}`.search(search.toLowerCase()) >= 0) tempFilteredProducts.push(filteredProducts[index])
                        else if(`${filteredProducts[index].name_ru.toLowerCase()}`.search(search.toLowerCase()) >= 0) tempFilteredProducts.push(filteredProducts[index])
                        else if(`${filteredProducts[index].name_en.toLowerCase()}`.search(search.toLowerCase()) >= 0) tempFilteredProducts.push(filteredProducts[index])
                        else if(`${filteredProducts[index].keywords.toLowerCase()}`.search(search.toLowerCase()) >= 0) tempFilteredProducts.push(filteredProducts[index])
                    }
                }
                for (let index in importedProducts){
                    if(`${importedProducts[index].name.toLowerCase()}`.search(search.toLowerCase()) >= 0) tempFilteredProducts.push(importedProducts[index])
                }
            } else {
                for (let index in products){
                    if(selectedSubcategory){
                        if(products[index].subcategory === selectedSubcategory){
                            tempFilteredProducts.push(products[index])
                        }
                    } else if(selectedCategory){
                        if(products[index].category === selectedCategory){
                            tempFilteredProducts.push(products[index])
                        }
                    } else {
                        tempFilteredProducts.push(products[index])
                    }
                }
                if(!selectedCategory && !selectedSubcategory){
                    for (let index in importedProducts){
                        tempFilteredProducts.push(importedProducts[index])
                    }
                }
            }
            setFilteredProducts(tempFilteredProducts)
            if(sortProducts !== 0) handleSortProducts(tempFilteredProducts)
        }
        pageLayout()
    }

    const getPriceFromObject = (obj) => {
        return obj.discounted_price ? +obj.discounted_price : +obj.price
    }

    const getCategories = () => {
        Axios.get('get-categories-subcategories/').then(res => {
            setCategories(JSON.parse(res.data.categories))
            setSubcategories(JSON.parse(res.data.subcategories))
        }).catch(() => {
            setTimeout((e) => { if(e){getCategories()} }, 10000)
        })
    }

    const getSelectedCategory = () => {
        if(categories){
            for(let index in categories){
                if(categories[index].pk === selectedCategory){
                    return categories[index]
                }
            }
        }
        return null
    }

    const getProducts = (limit = 0) => {
        Axios.get(`get-products/${limit}`, {params : cookies.auth}).then(res => {
            setProducts(res.data.products)
            setImportedProducts(res.data.imported_products)
            localStorage.setItem('products', JSON.stringify(res.data))
            setTimeout(() => setCookie('allowResetPagination', true), 1000)
        }).catch(() => {
            setTimeout((e) => { if(e){getProducts()} }, 10000)
        })
    }

    const notSuccess = () => {
        alert(t('not_success_auth'))
        removeCookie('auth')
        removeCookie('user')
    }

    const addToCart = (pk, qty, name) => {
        if(cookies[name]){
            let cart = cookies[name]
            if(cart[pk]){
                if(cart[pk] === 1 && qty < 1){
                    delete cart[pk]
                } else{
                    if(cart[pk] < 99) cart[pk] = cart[pk] + qty
                    else if(qty < 0) cart[pk] = cart[pk] + qty
                }
            } else{
                cart[pk] = qty
            }
            setCookie(name, cart)
        } else {
            let cart = {}
            cart[pk] = qty
            setCookie(name, cart)
        }
        countCartQty()
    }

    const removeCart = (pk, name) => {
        if(cookies[name]){
            let cart = cookies[name]
            delete cart[pk]
            setCookie(name, cart)
        }
        countCartQty()
    }

    const productsInCart = () => {
        let total = 0
        if(products && cookies.cart){
            let tempCartProducts = []
            for (let cart_item in cookies.cart) {
                for (let product in products){
                    if(products[product].pk === +cart_item){
                        tempCartProducts.push({qty: cookies.cart[cart_item], ...products[product]})
                        total += products[product].discounted_price ?
                            (+products[product].discounted_price * cookies.cart[cart_item])
                            :
                            (+products[product].price * cookies.cart[cart_item])
                    }
                }
            }
            setCartProducts(tempCartProducts)
        }
        if(importedProducts && cookies.i_cart){
            let tempImportedCartProducts = []
            for (let i_cart_item in cookies.i_cart) {
                for (let product in importedProducts){
                    if(importedProducts[product].pk === +i_cart_item){
                        tempImportedCartProducts.push({qty: cookies.i_cart[i_cart_item], ...importedProducts[product]})
                        total += importedProducts[product].discounted_price ?
                            (+importedProducts[product].discounted_price * cookies.i_cart[i_cart_item])
                            :
                            (+importedProducts[product].price * cookies.i_cart[i_cart_item])
                    }
                }
            }
            setImportedCartProducts(tempImportedCartProducts)
        }
        setTotalPrice(total)
    }

    const countCartQty = () => {
        let qty = 0
        if(cookies['cart']){
            let cart = cookies.cart
            Object.entries(cart).map(([key, value]) => {qty += value})
        }
        if(cookies['i_cart']){
            let i_cart = cookies.i_cart
            Object.entries(i_cart).map(([key, value]) => {qty += value})
        }
        setCartQty(qty)
        productsInCart()
    }

    return (
        <div className={'container-fluid m-0 p-0'}>
            <Header props={props} />
            <ShopTopBar
                toggleDropdownProductsMenu={toggleDropdownProductsMenu}
                setToggleDropdownProductsMenu={setToggleDropdownProductsMenu}
                setShowPopover={setShowPopover}
                showPopover={showPopover}
                locationParam={locationParam}
                selectedCategory={selectedCategory}
                getSelectedCategory={getSelectedCategory}
                setSelectedCategory={setSelectedCategory}
                setSelectedSubcategory={setSelectedSubcategory}
                subcategories={subcategories}
                selectedSubcategory={selectedSubcategory}
                sortProducts={sortProducts}
                setSortProducts={setSortProducts}
                cartQty={cartQty}
                search={search}
                setSearch={setSearch}
                userModal={userModal}
                setUserModal={setUserModal}
                checkoutModal={checkoutModal}
                setCheckoutModal={setCheckoutModal}
            />
            {
                <ShopCategoryDropdown
                    catrgories={categories}
                    toggleDropdownProductsMenu={toggleDropdownProductsMenu}
                    selectedCategory={selectedCategory}
                    setSelectedSubcategory={setSelectedSubcategory}
                    setSelectedCategory={setSelectedCategory}
                    setToggleDropdownProductsMenu={setToggleDropdownProductsMenu}
                />
            }
            <div id={'products'} className={'container-fluid products'}>
                <div className={'row m-3'}>
                    {
                        filteredProducts && filteredProducts.map((filteredProduct, index) => {
                            if((page * showOnPage) > (index) && ((page * showOnPage) - showOnPage) < (index+1)) return <div className={'col'} key={filteredProduct.pk}>
                                <div className={'product-container-wrapper'}>
                                    <div className={'product-container'}>
                                        <div className={'product-img-container cursor-pointer'}>
                                            <a href={filteredProduct.imported ? `/product?i_prod=${filteredProduct.pk}` : `/product?prod=${filteredProduct.pk}`}>
                                                {
                                                    filteredProduct.image1
                                                        ?
                                                        <img
                                                            alt={filteredProduct.image1}
                                                            width={'180px'}
                                                            src={`${Axios.defaults.baseURL}${filteredProduct.image1.substring(1)}`}
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.src=noImage
                                                            }}
                                                            className={'product-img-container-hover-zoom'} />
                                                        :
                                                        <img alt={filteredProduct.image1} width={'180px'} src={noImage} />
                                                }
                                            </a>
                                        </div>
                                        <div className={'product-bottom-container text-center'}>
                                            <a href={filteredProduct.imported ? `/product?i_prod=${filteredProduct.pk}` : `/product?prod=${filteredProduct.pk}`}>
                                                {
                                                    filteredProduct.name ? <p className={'product-name'}>{filteredProduct['name']}</p> : <p className={'product-name'}>{filteredProduct[`name_${i18n.language}`]}</p>
                                                }
                                            </a>
                                            <p className={''}>
                                                <span className={`${!!filteredProduct.discounted_price ? 'old-price' : ''}`}>{Math.round(filteredProduct.price)}</span>
                                                {!!filteredProduct.discounted_price && <span className={'discounted-price'}> {Math.round(filteredProduct.discounted_price)}{t`amd`}</span>}
                                            </p>
                                            {
                                                filteredProduct.imported ?
                                                    cookies.i_cart && cookies.i_cart[filteredProduct.pk] && <div className="number-button-wrapper" >
                                                        <button type="button" className={'decrement'} onClick={() => addToCart(filteredProduct.pk, -1, 'i_cart')}>
                                                            <RemoveIcon />
                                                        </button>
                                                        <span>{cookies.i_cart[filteredProduct.pk]}</span>
                                                        <button type="button" className={'increment'} onClick={() => addToCart(filteredProduct.pk, 1, 'i_cart')}>
                                                            <AddIcon />
                                                        </button>
                                                    </div>
                                                    :
                                                    cookies.cart && cookies.cart[filteredProduct.pk] && <div className="number-button-wrapper" >
                                                        <button type="button" className={'decrement'} onClick={() => addToCart(filteredProduct.pk, -1, 'cart')}>
                                                            <RemoveIcon />
                                                        </button>
                                                        <span>{cookies.cart[filteredProduct.pk]}</span>
                                                        <button type="button" className={'increment'} onClick={() => addToCart(filteredProduct.pk, 1, 'cart')}>
                                                            <AddIcon />
                                                        </button>
                                                    </div>
                                            }
                                            {
                                                filteredProduct.imported ?
                                                    (!cookies.i_cart || !cookies.i_cart[filteredProduct.pk]) && <button onClick={() => addToCart(filteredProduct.pk, 1, 'i_cart')} className={'add-to-cart-button'}><img width={18} alt={"basket"} src={basketPNG}/></button>
                                                    :
                                                    (!cookies.cart || !cookies.cart[filteredProduct.pk]) && <button onClick={() => addToCart(filteredProduct.pk, 1, 'cart')} className={'add-to-cart-button'}><img width={18} alt={"basket"} src={basketPNG}/></button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            return ''
                        })
                    }
                </div>
            </div>
            <div className={'text-center pagination-section'}>
                {
                    filteredProducts && <div className="form-group mr-5 d-inline-block">
                        <label htmlFor="exampleFormControlSelect1"><small>{t`show_on_page`}</small></label>
                        <select
                            className="form-control"
                            id="exampleFormControlSelect1"
                            value={showOnPage}
                            onChange={(e) => {
                                setShowOnPage(e.target.value)
                                setPage(1)
                            }}
                        >
                        <option value={defaultShowOnPage}>{defaultShowOnPage}</option>
                            {defaultShowOnPage*2 < filteredProducts.length && <option value={defaultShowOnPage*2}>{defaultShowOnPage*2}</option>}
                            {defaultShowOnPage*3 < filteredProducts.length && <option value={defaultShowOnPage*3}>{defaultShowOnPage*3}</option>}
                            {defaultShowOnPage*4 < filteredProducts.length && <option value={defaultShowOnPage*4}>{defaultShowOnPage*4}</option>}
                            {defaultShowOnPage < filteredProducts.length && <option value={filteredProducts.length}>{t`all`} - {filteredProducts.length}</option>}
                        </select>
                    </div>
                }
                {
                    filteredProducts && <Pagination
                        className={'d-inline-block mt-3 ml-5'}
                        count={Math.ceil(filteredProducts.length / showOnPage) < 1 ? 1 : Math.ceil(filteredProducts.length / showOnPage)}
                        variant="outlined"
                        size="large"
                        page={page}
                        onChange={(event, value) => setPage(value)}
                    />
                }
            </div>
            <Footer props={props} />
            <UserModal
                userModal={userModal}
                setUserModal={setUserModal}
            />
            <CheckoutModal
                checkoutModal={checkoutModal}
                setCheckoutModal={setCheckoutModal}
                products={products}
                importedProducts={importedProducts}
                cartProducts={cartProducts}
                setCartProducts={setCartProducts}
                importedCartProducts={importedCartProducts}
                setImportedCartProducts={setImportedCartProducts}
                totalPrice={totalPrice}
                addToCart={addToCart}
                removeCart={removeCart}
                props={props}
                countCartQty={countCartQty}
            />
        </div>
    );
}
