export const handlePreloader = (afterMS) => {
    document.querySelector('#preloader').style.display = 'block'
    document.querySelector('body').className = 'overflow-hidden'
    setTimeout(() => {
        document.querySelector('#root').style.display = 'block'
        document.querySelector('#preloader').className = 'hide'
    }, afterMS)
    setTimeout(() => {
        document.querySelector('#preloader').style.display = 'none'
        document.querySelector('#preloader').className = ''
        document.querySelector('body').className = 'body'
    }, afterMS * 2)
};

export const pageLayout = (timeout = 300) => {
    setTimeout(() => {
        try{
            let productsSection = document.querySelector('#products')
            if(window.innerWidth >= 1000){
                const productsTopBar = document.getElementById('products-top-bar')
                productsSection.style.marginTop = `${productsTopBar.offsetHeight+50}px`
            } else {
                productsSection.style.marginTop = '130px'
            }
            const topBarHeight = document.querySelector('#products-top-bar').getBoundingClientRect().top + 82
            document.querySelector('#dropdown-products-menu').style.top = `${topBarHeight}px`
            document.querySelector('#dropdown-products-menu').style.height = `${window.innerHeight - topBarHeight}px`
        } catch (e){}

    }, timeout)
}
window.addEventListener('resize', () => pageLayout())
window.addEventListener('DOMContentLoaded', () => pageLayout(1000))

window.addEventListener('scroll',function(e) {
    try{
        const topBarHeight = document.querySelector('#products-top-bar').getBoundingClientRect().top + 82
        setTimeout(() => {
            document.querySelector('#dropdown-products-menu').style.top = `${topBarHeight}px`
            document.querySelector('#dropdown-products-menu').style.height = `${window.innerHeight - topBarHeight}px`
        })
        let productsTopBar = document.getElementsByClassName('products-top-bar')
        for (let index in productsTopBar){
            if(document.documentElement.scrollTop > 100){
                productsTopBar[index].style.top = '0px'
            } else if(document.documentElement.scrollTop > 50){
                productsTopBar[index].style.top = '50px'
            } else {
                productsTopBar[index].style.top = '75px'
            }
        }
    } catch (e){}
})
