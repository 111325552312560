import Axios from "../../helpers/Axios";
import React from "react";
import {useTranslation} from "react-i18next";


export default function ShopCategoryDropdown(props){

    const { i18n, t } = useTranslation()

    return <div id={'dropdown-products-menu'} className={`dropdown-products-menu ${props.toggleDropdownProductsMenu ? '' : 'hide'}`}>
        <p className={'m-3 mb-0 h5 border-bottom border-dark'}>{t`departments`}</p>
        <div className={'container-fluid p-3'}>
            <div className={'row'}>
                {
                    props.catrgories && props.catrgories.map((category, index) => {
                        return (<div className={'col mt-3'} key={index}>
                            <div className={'product-center'}>
                                <div
                                    className={`cursor-pointer p-1 ${props.selectedCategory === category.pk ? 'category-selected-border' : 'category-border'}`}
                                    onClick={() => {
                                        if(props.selectedCategory !== category.pk){
                                            props.setSelectedSubcategory('')
                                            props.setToggleDropdownProductsMenu(false)
                                        } else props.setToggleDropdownProductsMenu(false)

                                        props.setSelectedCategory(category.pk)
                                    }}>
                                    <img width={'180px'} src={`${Axios.defaults.baseURL}media/${category.fields.image}`}></img>
                                    <div className={'category-text'}><p className={'text-center m-0'}>{category.fields[`name_${i18n.language}`]}</p></div>
                                </div>
                            </div>
                        </div>)
                    })
                }
            </div>
        </div>
    </div>
}
