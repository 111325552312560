import React, {useState, useEffect} from "react"
import { useHistory } from 'react-router-dom'

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CloseIcon from '@mui/icons-material/Close';
import MenuOpen from '@mui/icons-material/MenuOpen';

import {handlePreloader} from '../../helpers/Utils'
import './style.css'
import armFlag from '../../media/armenia-flag.jpg'
import rusFlag from '../../media/russia-flag.jpg'
import usaFlag from '../../media/usa-flag.jpg'
import {useTranslation} from "react-i18next";
import Axios from "../../helpers/Axios";
import OutsideClickHandler from 'react-outside-click-handler';

export default function Header(props) {
    const [slideBarOpen, setSlideBarOpen] = useState(false)
    const [languageOpen, setLanguageOpen] = useState(false)

    const { i18n, t } = useTranslation();

    const page = window.location.pathname.split('/')[1] ? window.location.pathname.split('/')[1] : ''
    const history = useHistory();

    // Change page
    useEffect(() => {
        if(history.action === 'PUSH') {
            handlePreloader(500)
        }
    }, [history])

    const changeLanguage = (lang, this_=null) => {
        handlePreloader(250)
        i18n.changeLanguage(lang).then(() => {
            document.querySelector('html').lang = lang
        })
    }

    const header_logo = () => {
        return props.props.configs && <img
                                        onClick={() => history.push('/')}
                                        alt={'logo'} className={'logo cursor-pointer ml-3'}
                                        src={`${Axios.defaults.baseURL}media/${props.props.configs.logo_full}`}
                                      />

    }

    const language_bar = () => {
        return <div className={'language-menu'}>
            <span className={`d-block justify-content-center`}>
                {i18n.language === 'hy' && <img alt={'armenia-flag'} width={'30px'} className={'rounded-circle cursor-pointer'} src={armFlag} onClick={() => setLanguageOpen(!languageOpen)} />}
                {i18n.language === 'ru' && <img alt={'russia-flag'} width={'30px'} className={'rounded-circle cursor-pointer'} src={rusFlag} onClick={() => setLanguageOpen(!languageOpen)} />}
                {i18n.language === 'en' && <img alt={'usa-flag'} width={'30px'} className={'rounded-circle cursor-pointer'} src={usaFlag} onClick={() => setLanguageOpen(!languageOpen)} />}
            </span>

            {
                languageOpen && <div onClick={() => setLanguageOpen(false)} className={'language-dropdown'}>
                    <OutsideClickHandler onOutsideClick={() => setLanguageOpen(false)}>
                        <div className={'position-relative'}>
                            <div className={'language-dropdown-wrap'}>
                                <span className={`d-block justify-content-center ${i18n.language === 'hy' ? 'hide' : ''}`}>
                                    <img onClick={() => changeLanguage('hy')} alt={'armenia-flag'} width={'30px'} className={'rounded-circle cursor-pointer'} src={armFlag} />
                                </span>
                                    <span className={`d-block justify-content-center ${i18n.language === 'ru' ? 'hide' : ''}`}>
                                    <img onClick={() => changeLanguage('ru')} alt={'russia-flag'} width={'30px'} className={'rounded-circle cursor-pointer'} src={rusFlag} />
                                </span>
                                    <span className={`d-block justify-content-center ${i18n.language === 'en' ? 'hide' : ''}`}>
                                    <img onClick={() => changeLanguage('en')} alt={'usa-flag'} width={'30px'} className={'rounded-circle cursor-pointer'} src={usaFlag} />
                                </span>
                            </div>
                        </div>
                    </OutsideClickHandler>
                </div>
            }
        </div>
    }
    const open_header = () => {
        return <div className={'row'}>
                    <div className={'col-2 col-xl-2 col-xxl-2 m-0 p-0 position-static'}>
                        {header_logo()}
                    </div>
                    <div className={'col-8 col-xl-9 col-xxl-9 m-0 p-0 h5 text-right menu-text menu-text-shadow'}>
                        <a href={'/'} className={page === '' ? 'header-underline' : ''}>
                            <b>{ t`home` }</b>
                        </a>
                        <a href={'shop'} className={`ml-5  ${page === 'shop' ? 'header-underline' : ''}`}>
                            <b>{ t`shop` }</b>
                        </a>
                        <a href={'contact_us'} className={`ml-5  ${page === 'contact_us' ? 'header-underline' : ''}`}>
                            <b>{ t`contact_us` }</b>
                        </a>
                    </div>
                    <div className={'col-2 col-xl-1 col-xxl-1 m-0 p-0 h6 text-right'}>
                        { language_bar() }
                    </div>
                </div>
    }
    const sidebar_header = () => {
        return (
            <div className={'row'}>
                <div className={'col-4 m-0 p-0 position-static'}>
                    {header_logo()}
                </div>
                <div className={'col-8 m-0 p-0 text-right menu-text-shadow'}>
                    <span
                        onClick={() => setSlideBarOpen(true)}
                        className={'cursor-pointer'}
                    >
                        <MenuOpen className={'swiper-icon'} />
                    </span>
                </div>
                <SwipeableDrawer
                    className={'swiper'}
                    anchor={'right'}
                    open={slideBarOpen}
                    onClose={() => setSlideBarOpen(false)}
                    onOpen={() => ''}
                >
                    <div className={'swiper-div m-0 p-0'}>
                        <div className={'container-fluid sidebar-header'}>
                            <div className={'row'}>
                                <div className={'col-8'}>
                                    <span
                                        onClick={() => setSlideBarOpen(false)}
                                        className={'cursor-pointer'}
                                    >
                                        <CloseIcon style={{color: '#000000'}} className={'swiper-icon'} />
                                    </span>
                                </div>
                                <div className={'col-4 text-right h6'}>
                                    { language_bar() }
                                </div>
                            </div>
                        </div>
                        <div className={'container-fluid h4 text-center'}>
                            <p className={'mt-4 cursor-pointer'} onClick={() => history.push('/')}>{ t`home` }</p>
                            <p className={'mt-4 cursor-pointer'} onClick={() => history.push('shop')}>{ t`shop` }</p>
                            <p className={'mt-4 cursor-pointer'} onClick={() => history.push('contact_us')}>{ t`contact` }</p>
                        </div>
                    </div>
                </SwipeableDrawer>
            </div>
        )
    }
    return (
        <div className={'container-fluid header'}>
            <div className={'container-fluid menu'}>
                { props.props.pageWidth > 990 ? open_header() : sidebar_header() }
            </div>
        </div>
    )
}
