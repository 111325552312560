import React from "react"

import './style.css'
import Axios from "../../helpers/Axios";
import {useTranslation} from "react-i18next";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Viber from "../../static/viber.svg";
import TelegramIcon from "@mui/icons-material/Telegram";
import {useHistory} from "react-router-dom";

export default function Footer(props) {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div className={'container-fluid footer'}>
            <div className={'p-3 footer-container'}>
                <div className={'row footer-container-first-child'}>
                    <div className={'col-12 col-sm-12 col-md-2'}>
                        {props.props.configs && <img alt={'logo'} className={'footer-logo mb-3'} src={`${Axios.defaults.baseURL}media/${props.props.configs.logo}`} />}
                    </div>
                    <div className={'col-12 col-sm-12 col-md-5'}>
                        <div className={'container-fluid text-center'}>
                            <p><a className={'mt-3'} href={'/'}>{t`home`}</a></p>
                            <p><a className={'mt-3'} href={'shop'}>{t`shop`}</a></p>
                        </div>
                    </div>
                    <div className={'col-12 col-sm-12 col-md-5 text-center'}>
                        <p><a className={'mt-3'} href={'contact_us'}>{t`contact_us`}</a></p>
                        {/*<p className={'mt-3 cursor-pointer'}>info@stepanyan.technology</p>*/}
                    </div>
                    <div className={'col-12 mb-3 mt-1 p-0 text-center'}>
                        <a href={'https://facebook.com/'} target={'_blank'} rel="noreferrer" className={'mr-3 mr-md-5 cursor-pointer'}>
                            <FacebookIcon sx={{ fontSize: 50, color: '#4867aa' }} />
                        </a>
                        <a href={'whatsapp://send?abid=phonenumber'} target={'_blank'} rel="noreferrer" className={'mr-3 mr-md-5 cursor-pointer'}>
                            <WhatsAppIcon sx={{ fontSize: 50, color: '#3cc14e' }} />
                        </a>
                        <a href={'viber://chat?number=12345678'} target={'_blank'} rel="noreferrer" className={'mr-3 mr-md-5 cursor-pointer'}>
                            <img src={Viber} alt={'viber icon'} style={{ width: 46 }} />
                        </a>
                        <a href={'https://t.me/USERNAME'} target={'_blank'} rel="noreferrer" className={'mr-3 mr-md-5 cursor-pointer'}>
                            <TelegramIcon sx={{ fontSize: 50, color: '#1e96c8' }} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
