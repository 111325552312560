import React,  {useEffect} from "react"

import {handlePreloader} from '../../helpers/Utils'
import Axios from '../../helpers/Axios'
import { useCookies } from "react-cookie";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Client() {
    const [cookies, setCookie] = useCookies();
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        handlePreloader(10000)
        const urlParams = new URLSearchParams(window.location.search);
        Axios.post('auth/', {'auth': urlParams.get('auth')}).then(res => {
            setCookie('auth', JSON.stringify(res.data))
            history.push('shop')
        }).catch(function (error) {
            alert(t('not_success_auth'))
            history.push('/')
        });
    }, [])

    return (
        <div >

        </div>
    );
}
